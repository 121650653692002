.ais-Pagination-list {
    padding-inline-start: 0;
    margin: 0;
}

.ais-Menu {
    margin: 15px 0;
}

.ais-Menu-list {
    margin: 0;
    list-style: none;
    display: flex;
    padding-inline: 0;
    
    > .ais-Menu-item {
        height: 28px;
        margin-right: 20px;
        
        &.ais-Menu-item--selected {
            background: $lightGrey;
            
            * {
            }
            
        }
        
        .ais-Menu-link {
            color: $primaryColor;
        }
        
        .ais-Menu-label {
            text-transform: capitalize;
        }
        
        .ais-Menu-count {
            margin: 0 0 0 10px;
            font-size: 11px;
            color: $grayColor;
        }
        
    }
}

.algolia-searchbox {
    
    background: #fff;
    position: relative;
    color: $grayColor;
    
    .icon {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        font-size: 19px;
        color: $grayColor
    }
    
    input {
        padding-left: 40px;
        padding-right: 40px;
        color: $primaryColor;
        
        &::placeholder {
          color: var(--f7-searchbar-placeholder-color);
          opacity: 1;
        }
        
    }
    
    .input-clear-button {
        right: 8px;    
        color: $grayColor;
    }
    
}

